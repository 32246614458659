.about{
    background-image: url('../images/aboutus2.jpg');
    background-size: cover;
    height: min(30vh);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 4rem;
}

.about-img{
    width: 90%;
}

.about-text{
    padding-top: 20px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.about-text h2 span{
    color: #F5CB5D;
}

.img-flex{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 766px){
    .about{
        font-size: 2rem;
    }

    .img-flex{
        display: none;
    }
}

@media only screen and (max-width: 1200px){
    
    .about-img{
        width: 70%;
    }

    .about-text{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}