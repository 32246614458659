.info-row{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-top: 20px;
}

.info_icon{
    display: flex;
    align-items: center;
    color: #F5CB5D;
    margin-right: 10px;
}

.min-vh{
    height: min(53.5vh);
}

.bg-black{
    background-color: #000;
}

.text-white{
    color: #fff;
}

.text-center{
    text-align: center;
}

.border-bottom{
    border-bottom: 1px solid;
}

.contact-title{
    margin: 0;
    padding: 25px;
}

@media only screen and (max-width: 768px){
    .info_text{
        font-size: 18px;
    }
}