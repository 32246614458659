.service-container{
    background: rgb(93,90,81);
    background: linear-gradient(-3deg, rgba(93,90,81,1) 0%, rgba(0,0,0,1) 80%);
    min-height: 200px;
    color: #fff;
    padding: 50px 0;
}

.service-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
}

.service-title h1{
    font-size: 3rem;
}

.service-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    width: 100%;
    border: 1px solid #F5CB5D;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 26px;
    padding: 20px;
}

.service-icon{
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 2.5rem;
    color: #000;
}

.service-cart h4{
    color: #000;
    padding: 10px 0;
}

.service-cart p{
    color: #000;
}

.bg-yellow{
    background-color: #F5CB5D;
}

.bg-white{
    background-color: #FFF;
}

.bg-grey{
    background-color: #737373;
}

.txt-white{
    color: #fff;
}