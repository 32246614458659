.service-wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #000;
    color: #fff;    
    border-bottom: 1px solid #333333;
}

.service-wrapper h1{
    padding: 20px;
}

ul li{
    color: #F5CB5D;
}

ul li span{
    color: #fff;
}

.text-center{
    text-align: center;
}

.text-bold{
    font-weight: 700;
}

.bg-black{
    background-color: #000;
}

.padding-y{
    padding-top: 10px;
    padding-bottom: 10px;
}