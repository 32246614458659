.bg-black{
    background-color: #000;
}

.hero{
    height: min(90vh);
    width: 100%;
    display: flex;
    align-items: center;
}

.hero-text{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    padding: 10px;
    color: #fff;
}

.hero-text h1 span{
    color: #737373;
}

.hero img {
    width: 100%;
    border-radius: 10px;
}

.hero-text h1{
    font-size: 5rem;
    animation: fadeIn 2s ease-in;
}

.hero-text h3{
    font-size: 2rem;
}

.hero-img{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1024px){
    .hero{
        flex-direction: column;
        justify-content: center;
        height: min(60vh);
    }
    
    .hero-text h1{
        font-size: 3rem;
        text-align: center;
    }
    
   .hero-text h3{
        font-size: 1rem;
        text-align: center;
        margin-top: 0;
   }

   .hero img {
    width: 90%;
    border-radius: 10px;
    }

   .hero-btn{
    max-width: 150px;
   }

   .hero-btn a{
    font-size: 1rem;
   }
}

@media only screen and (max-width: 768px){
    .hero{
        flex-direction: column;
        justify-content: center;
        height: min(40vh);
    }
}

@keyframes fadeIn {  
    from {  
        opacity:0;  
    }  
 
    to {  
        opacity:1;  
    }  
 }