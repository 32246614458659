.footer_logo{
    max-width: 150px;
    width: 100%;
}

.footer_list{
    list-style: none;
    padding-left: 0;
}

.footer_list_heading{
    margin-bottom: 20px;
}

.footer_list a{
    text-decoration: none;
    color: #f1f1f1;
}

.footer_list a:hover{
    color: #F5CB5D;
}

.footer_icon{
    display: flex;
    align-items: center;
    color: #F5CB5D;
    font-size: 1.5rem;
}

.footer_icon_text{
    color: #f1f1f1;
    font-size: 1.1rem;
    margin: 0;
    padding-left: 10px;
}

.footer_socialmedia_icon_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_socialmedia_icon_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: #000;
    background-color: #F5CB5D;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.footer_socialmedia_icon_box:hover{
    background-color: #f1f1f1;
}

.footer_socialmedia_icon_link{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor:pointer;
    color: #000;
    margin: 0;
    padding: 0;
}

.bg-black{
    background-color: #000;
}