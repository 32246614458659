:root{
    --mainColor: #343a40;
    --secondaryColor: #F5CB5D;
    --textColor: #EEE;
}

header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: #000;
    color: var(--textColor);
    border-bottom: 1px solid #333333;
}

header img{
    width: 180px;
    height: auto;
}

.link{
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: var(--textColor);
    margin: 0 2rem;
}

nav a{
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: var(--textColor);
    margin: 0 2rem;
}

nav a:after{    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--secondaryColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

nav a:hover{
    color: var(--secondaryColor);
}

nav a:hover:after{ 
    width: 100%; 
    left: 0; 
}

header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.6rem;
}

@media only screen and (max-width: 1024px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: #000;
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 99;
    }

    header .responsive-nav{
        transform: none;
    }

    nav .nav-close-btn{
        display: flex;
        align-items: center;
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    nav a{
        font-size: 1.5rem;
    }
}