.consultant-title{
    text-align: center;    
    border-bottom: 1px solid #777;
    padding: 30px 0;
}

.consultant-grid-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    padding: 87px 50px;
}

.consultant-grid-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #555;
    padding-top: 10px;
    padding-bottom: 10px;
}

.consultant-grid-item p{
    font-size: 22px;
    margin-top: 5px;
}

.consultant-img-wrapper{
    padding: 10px;
    max-width: 150px;
    max-height: 150px;
}

.consultant-img{
    width: 100%;
}

.bg-black{
    background-color: #000;
}

.text-white{
    color: #fff;
}