.title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.project-title{
    display: inline-flex;
    text-align: center;
    margin: 0;
    font-size: 3rem;
}

.kosgeb-success p{
  font-size: 1.1rem;
}

.kosgeb-success p span{
  font-size: 1.1rem;
  color: #F5CB5D;
}

.kosgeb-project-wrapper{  
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.kosgeb-project-box{  
  width: 90%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #777;
}

.kosgeb-project-img-box{  
  display: flex;
  justify-content: center;
  max-width: 300px;
  max-height: 200px;
}

.kosgeb-project-img{
  width: 70%;
  padding: 10px;
}

.kosgeb-project-text-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.kosgeb-project-text-box-title{
  height: 100px;
}

.kosgeb-project-text-box h3{
  margin: 0;
  padding: 10px;
}

.news-logo-box{
  position: absolute;
  top: 10px;
  right: 70px;
  width: 50px;
  height: 50px;
}

.news-logo{
  width: 100%;
}

.news-date-box{
  position: absolute;
  top: 10px;
  left: 90px;
  color: #F5CB5D;
}

.news-link{
  text-decoration: none;
  color: #f1f1f1;
}

@media only screen and (max-width: 992px){
  .kosgeb-project-img{
    width: 50%;
  }

  .news-logo-box{
    right: 40px;
  }

  .news-date-box{
    left: 50px;
  }

  .kosgeb-project-text-box-title{
    margin-bottom: 10px;
  }
  
  .kosgeb-project-text-box-title h3{
    font-size: 18px;
  }

  .kosgeb-project-text-box p{
    font-size: 12px;
  }
}










.bg-black{
    background-color: #000;
}

.text-white{
    color: #fff;
}

.text-center{
    text-align: center;
}

.border-bottom{
    border-bottom: 1px solid;
}

.pie-wrap {
    height: 100px;
    position: relative;
    margin: 20px
  }
  
  .pie-wrap:before {
    content: "";
    display: block;
    background-color: #e4dac2;
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
  }
  
  .slice-wrap:before {
    content: " ";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    clip: rect(0px, 50px, 100px, 0px);
    background-color: #F5CB5D;
    transform: rotate(10deg);
  }
  
  .slice-wrap {
    position: absolute;
    width: 100px;
    height: 100px;
    clip: rect(0px, 100px, 100px, 50px);
  }
  
  .slice2.slice-wrap {
    transform: rotate(180deg);
    animation: spin3 1s linear forwards 3s;
  }
  
  .slice1:before {
    animation: 2s spin linear forwards;
  }
  
  .slice2:before {
    /* older webkit seems buggy with zero so use 0.00001 if you notice something strange*/
    transform: rotate(0deg);
    animation: 2s spin2 linear 2s forwards;
  }
  
  .pie-wrap:after {
    /* remove this if you want pie shape to show*/
    content: " ";
    width: 80px;
    height: 80px;
    background: #fde3a7;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: 999;
  }
  
  .pie-wrap2:after {
    display: none
  }
  
  @keyframes spin {
    from {
      transform: rotate(10deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
  @keyframes spin2 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }