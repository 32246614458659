.container-grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    padding: 50px;
}

.service-title-page{
    text-align: center;    
    border-bottom: 1px solid #777;
    padding: 30px 0;
}

.container-grid img{
    display: block;
    width: 100%;
}

.grid-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #555;
    padding-top: 10px;
    padding-bottom: 10px;
}

.grid-item h5{
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
}

.grid-img-wrapper{
    padding: 10px;
    max-width: 100px;
    max-height: 100px;
}

.grid-img{
    width: 50%;
}

.service-link{
    text-decoration: none;
    color: #fff;
}

.service-link:hover{
    margin-top: -8px;
    transition: margin 0.2s ease-in-out;
}

.bg-black{
    background-color: #000;
}

.text-white{
    color: #fff;
}