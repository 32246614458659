.brand-img{
    width: 100%;
}

.brand-cart{
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 250px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.5);
    margin: 26px;
    padding: 20px;
}

.brand-cart span {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: #F5CB5D;
    margin-right: 5px;
}

.brand-cart p{
    font-weight: 500;
    margin: 0;
}

.brand-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.brand-title h2{
    font-size: 2.5rem;
}

.brand-title p{
    font-size: 1.2rem;
}
